<template>
	<div class="card card-custom card-stretch gutter-b">
		<div class="card-header border-0">
			<h3 class="card-title font-weight-bolder text-dark">
				{{ name }} - IP SLA
				<a
					v-if="voipInstanceId && ['admin', 'noc', 'staff'].includes(currentUser.role)"
					target="_blank"
					:href="`https://orionweb2017.itcglobal.com/Orion/Voip/IpSlaOperation.aspx?NetObject=ISOP%3a${voipInstanceId}`"
					v-b-tooltip="'Open in Solarwinds'"
				>
					<img class="ml-5" width="20" :src="'/media/logos/solarwinds.png'" />
				</a>
			</h3>
		</div>

		<div class="card-body pt-2">
			<ITCSpinner :loaded="loaded">
				<div v-if="error" class="pt-5">
					<b-alert show variant="warning" class="m-0">
						<span class="svg-icon-danger">
							<inline-svg src="/media/svg/icons/Code/Warning-2.svg"></inline-svg>
						</span>
						{{ errorMsg }}
					</b-alert>
				</div>
				<dygraphs v-if="dyData.length > 0" :data="dyData" :options="dyOpts" />
			</ITCSpinner>
		</div>
	</div>
</template>

<script>
const date = new Date();
const offset = date.getTimezoneOffset() * 60000;
import { mapGetters } from 'vuex';

export default {
	name: 'SolarwindsIPSLA',
	props: ['name', 'id', 'ipsla', 'properties', 'devices', 'updated'],
	data() {
		return {
			loaded: false,
			voipInstanceId: null,
			dyData: [],
			dyOpts: {},
			error: false,
			errorMsg: 'There was an error retrieving data for this device',
		};
	},
	components: {
		ITCSpinner: () => import('@/view/content/loaders/itcSpinner.vue'),
		dygraphs: () => import('@/view/content/lib/dygraphs.vue'),
	},
	methods: {
		processData(data) {
			var gd = [];
			if (!data?.data || data.data.length == 0) {
				throw { data: data, e: 'no data' };
			}
			this.dyOpts = data.opts;
			if (data.opts.labels.length == 5) {
				Object.keys(data.data).forEach(ts => {
					var o = data.data[ts];
					gd.push([new Date(ts * 1000 + offset), o.rtt < 4000 ? o.rtt : null, o.jitter, o.loss_DS, o.loss_SD]);
				});

				this.dyOpts.series = {
					'Upstream Pkt Loss': { axis: 'y2' },
					'Downstream Pkt Loss': { axis: 'y2' },
				};

				this.dyOpts.y2label = 'Packet Loss';
			} else {
				Object.keys(data.data).forEach(ts => {
					var o = data.data[ts];
					gd.push([new Date(ts * 1000 + offset), o.rtt < 4000 ? o.rtt : null]);
				});
			}

			this.dyData = gd;

			if (this.dyOpts.labels.includes('Jitter')) {
				this.dyOpts.ylabel = 'RTT / Jitter';
			} else {
				this.dyOpts.ylabel = 'RTT';
			}

			this.dyOpts.axes = {
				y: {
					valueFormatter: function(y, opts, series) {
						if (series.includes('Jitter') || series.includes('RTT')) return y + 'ms';
					},
				},
				y2: {
					y2label: 'Packet Loss',
					valueRange: [0, 100],
					valueFormatter: function(y2, opts, series) {
						if (series.includes('Upstream Pkt Loss') || series.includes('Downstream Pkt Loss')) return y2 + '%';
					},
					axisLabelFormatter: function(y2) {
						return Math.round(y2) + '%';
					},
				},
			};

			this.loaded = true;
		},
		load() {
			if (this.devices[this.id] && this.devices[this.id].Source) {
				this.voipInstanceId = this.devices[this.id].Source;
			}
			this.loaded = false;
			if (this.ipsla && Object.keys(this.ipsla).length <= 1) {
				this.$http
					.get(`swipsla/${this.id}`)
					.then(resp => {
						this.processData(resp.data.data);
					})
					.catch(e => {
						if (e.data && e.data == 'No data for selected range') {
							this.error = true;
							this.errorMsg = e.data;
						} else {
							this.errorMsg = 'There was an error retrieving data for this device.  ' + e;
							this.error = true;
						}
						this.loaded = true;
					});
			} else {
				// handle additional IP SLA's
				var promises = [];
				var qdata = [];

				// this can cause the same API call more than once, fix that down the road
				Object.keys(this.ipsla).forEach(i => {
					promises.push(
						this.$http.get(`swipsla/${i}`).then(d => {
							qdata[i] = d;
						})
					);
				});

				Promise.all(promises).then(() => {
					var rawdata = {};
					var timestamps = [];
					var legend = [];

					Object.keys(this.ipsla).forEach(i => {
						//create an array of all the timestamps because each data set may have different timestamps
						if (Object.keys(this.ipsla[i]).length > 0) {
							//don't include timestamps if no properties are set to show for a device

							timestamps = [...new Set([...timestamps, ...Object.keys(qdata[i].data.data.data)])];
						}
					});

					// create array of all ip sla's and only include the checked values from widget properties
					timestamps.forEach(t => {
						Object.keys(this.ipsla).forEach(i => {
							var n = this.ipsla[i];
							if (typeof rawdata[t] === 'undefined') {
								rawdata[t] = [];
							}
							if (n.rtt) {
								rawdata[t].push(typeof qdata[i].data.data.data[t] != 'undefined' ? qdata[i].data.data.data[t].rtt : null);
							}
							if (n.jitter) {
								rawdata[t].push(typeof qdata[i].data.data.data[t] != 'undefined' ? qdata[i].data.data.data[t].jitter : null);
							}
							if (n.lossDS) {
								rawdata[t].push(typeof qdata[i].data.data.data[t] != 'undefined' ? qdata[i].data.data.data[t].loss_DS : null);
							}
							if (n.lossSD) {
								rawdata[t].push(typeof qdata[i].data.data.data[t] != 'undefined' ? qdata[i].data.data.data[t].loss_SD : null);
							}
						});
					});

					// pivot raw data
					var graphdata = [];
					Object.keys(rawdata).forEach(j => {
						var o = rawdata[j];
						o.unshift(new Date(j * 1000 + offset));
						graphdata.push(o);
					});

					// setup legend
					var legend = ['Timestamp'];
					Object.keys(this.ipsla).forEach(i => {
						var n = this.ipsla[i];
						var nameStr = this.properties.showName == true || typeof this.properties.showName == 'undefined' ? this.devices[i].Name + ' ' : '';
						if (n.rtt) {
							legend.push(nameStr + 'RTT(ms)');
						}
						if (n.jitter) {
							legend.push(nameStr + 'Jitter(ms)');
						}
						if (n.lossDS) {
							legend.push(nameStr + 'Pkt Loss Upstream');
						}
						if (n.lossSD) {
							legend.push(nameStr + 'Pkt Loss Downstream');
						}
					});

					var graphopts = {
						labels: legend,
						labelsKMG2: false,
						labelsSeparateLines: false,
						fillGraph: true,
						connectSeparatedPoints: true,
						includeZero: true,
						axes: {
							y: {
								valueFormatter: function(y, opts, series) {
									if (series.includes('Jitter') || series.includes('RTT')) return y + 'ms';
									else return y + '%';
								},
							},
						},
					};

					var vm = this;
					if (this.properties?.threshold) {
						graphopts.underlayCallback = function(ctx, area, g) {
							var HighCoords = g.toDomCoords(0, vm.properties.threshold);
							var high = HighCoords[1];
							ctx.beginPath();
							ctx.setLineDash([5, 15]);
							ctx.moveTo(0, high);
							ctx.lineTo(10000, high);
							ctx.strokeStyle = 'red';
							ctx.stroke();
							ctx.setLineDash([]);
						};
					}

					this.dyOpts = graphopts;
					this.dyData = graphdata;
					this.loaded = true;
				});
			}
		},
	},
	watch: {
		updated() {
			this.load();
		},
	},
	mounted() {
		this.load();
	},
	computed: {
		...mapGetters(['currentUser']),
	},
};
</script>

<style></style>
